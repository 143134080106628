import {
    AvatarCreator,
    AvatarCreatorConfig,
  } from "@readyplayerme/react-avatar-creator";
import React, { useEffect, useRef} from "react";
import { useDispatch } from 'react-redux';
import { cleanMessage } from "../LiveChat/chatWindowComponents/ChatBubble";
import { useAppSelector } from "../../store/hooks";
import { setPlayer } from "../../store/appUser";
import { useNavigate } from "react-router";
  
  type Props = {
    style: {
      width: string,
      height: string, 
      border: string, 
      margin: number, 
      zIndex: number,
      borderRadius: string
    },
    width: string,
    height: string,
    close: Function | null,
    isModal: boolean,
    sendMessage: Function | null
  }

  const ReadyPlayerMeCreator: React.FC<Props> = ({style, width, height, close, isModal,sendMessage}) => {
    
    const dispatch = useDispatch()
    const history = useNavigate()
    const { player } = useAppSelector((state) => state.appUser)
    const playerRef = useRef(player)
    
    const config: AvatarCreatorConfig = {
      clearCache: false,
      bodyType: "fullbody",
      quickStart: false,
      language: "en",
      avatarId: window.avatarId ? window.avatarId : ""
    };
    

    useEffect(() => {
      playerRef.current = player
    },[player])
    
    // useEffect(() => {
    //   let avatarUrlCookie = Cookies.get("avatarUrl")
    //   if(avatarUrlCookie){
    //     let modelId = avatarUrlCookie?.split('/').pop()?.split('.')[0] ?? null;
    //     window.avatarId = modelId
    //   }
    //   console.log("Cookie: " + avatarUrlCookie)
    //   let nameCookie = Cookies.get("username")
    //     if(nameCookie){
    //         dispatch(setAvatarName(nameCookie))
    //     }
    // },)
    

    const handleOnUserSet = (event: any) => {
      console.log(`User Ready Player me ID is: ${event.data.id}`);
    };
  
    const handleOnAvatarExported = (event: any) => {
      let url = event.data.url
      let stateToUpdate = {
        ...playerRef.current,
        glbUrl: url
      };
      console.log("UPDATING AVATAR")
      console.log(stateToUpdate)
      dispatch(setPlayer(stateToUpdate))
      let modelId = url.split('/').pop()?.split('.')[0] ?? null;
      window.avatarId = modelId

      if(isModal && close){
        if(sendMessage){
          let data = {
            displayName: playerRef.current.displayName,
            avatarUrl: url,
            avatarProfilePic: url.replace(".glb", ".png"),
            colour: playerRef.current.colour
          }
          console.log("DATA")
          console.log(data)
          sendMessage("ReactClientManager", "SetLocalAvatar",  JSON.stringify(data));
        } 
        close(false)
      } else {
          history("/mv/demo")
        
      }

    };
  
    const handleUserAuthorized = (event: any) => {
      console.log(`User is:`, event.data);
    };
  
    const handleAssetUnlocked = (event: any) => {
      console.log(`Asset unlocked is: ${event.data.assetId}`);
    };

    
    function handleAvatarName(name:string){
      let clean = cleanMessage(name)
      let stateToUpdate = {
        ...playerRef.current,
        displayName: clean
      };
      dispatch(setPlayer(stateToUpdate))
    }

    function updateNameOnly(){
      //let nameCookie = Cookies.get("username")
      console.log("updateName Only")
      if(sendMessage != null && playerRef.current.displayName != ""){
        let data = {
          displayName: playerRef.current.displayName,
          avatarUrl: playerRef.current.glbUrl,
          avatarProfilePic: playerRef.current.glbUrl?.replace(".glb", ".png"),
          colour: playerRef.current.colour
        }
        sendMessage("ReactClientManager", "SetLocalAvatar",  JSON.stringify(data));
      }
      if(close){
        close(false)
      } 
  }


    return (
      <div style={{height: '100vh'}}>
       {window.avatarId !== null && <AvatarCreator
          subdomain="doglife.readyplayer.me/avatar?frameApi="
          config={config}
          style={style}
          onAvatarExported={handleOnAvatarExported}
          onUserAuthorized={handleUserAuthorized}
          onAssetUnlock={handleAssetUnlocked}
          onUserSet={handleOnUserSet}
        />}
        {isModal ? (
          <div style={{ position: 'absolute', top: '96%', right: '8%', zIndex: 1 }}>
            <label
              htmlFor="avatarName"
              style={{
                color: 'black'
              }}
            >
              Update Name:
            </label>
            <input
              type='text'
              value={player.displayName ? player.displayName : ""}
              onChange={e => {
                handleAvatarName(e.target.value);
              }}
              style={{
                marginLeft: '10px'
              }}
            />
            <button
              className='close-button-rpm'
              onClick={() => updateNameOnly()}
            >
              CLOSE
            </button>
          </div>
        ) : null}
        
      </div> 
    );
  }
  
  export default ReadyPlayerMeCreator;