import { useNavigate } from "react-router"
import { useEffect, useRef, useState } from "react"

export const LandingPage: React.FC = () => {

    const navigate =  useNavigate()
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [animate, setAnimate] = useState(false);
    const isFull = process.env.REACT_APP_FULL === "true";

    useEffect(() => {
        const interval = setInterval(() => {
          setAnimate(true);
          setTimeout(() => setAnimate(false), 1000);
        }, 2000);
    
        return () => clearInterval(interval);
      }, []);

    const handleSound = () => {
        if (videoRef.current) {
          videoRef.current.muted = !videoRef.current.muted; 
          videoRef.current.play(); 
        }
      };
    
    return(
        <div>
             <video autoPlay muted loop onClick={handleSound} ref={videoRef} style={{zIndex: 0}}>
                <source src="https://www.doglibre.com/assets/videos/doge-dance.webm" type="video/webm" />
                Your browser does not support the video tag.
            </video>
            
            <div className={`button-container`}>
               
                <div className="paw-container">
                  <img height={20} src="/images/paw.png" alt="left paw" className="rotating-paw"/>
                  <button className="loading-text" onClick={() => window.location.href = isFull ? "/mv/" : "/mv/demo"}>ENTER</button>
                  <img height={20} src="/images/paw.png" alt="right paw" className="rotating-paw" />
                </div>
            </div>
        </div>
    )
}