import { useEffect, useState } from "react"
import { RouteParams, UnityClient } from "./UnityClient";
import { AvatarPage } from "./AvatarPage/AvatarPage";
import { useParams } from "react-router";
import { setPlayer } from "../store/appUser";
import { useAppDispatch } from "../store/hooks";

export const globalIsHost = {
    isHost: false
} 


export const InboundLinkRouting: React.FC = () => {

    const {id} = useParams<RouteParams>();
    const [hasCookie, setHasCookie] = useState(false);
    const bypassId = "demo";
    const hostId = "b2124128-a607-487f-b9de-33b03750cab9";
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(id !== bypassId){
            fetchData(window.location.origin + "/user-profile")
            .then(data => {
                console.log('Data received:', data);

                dispatch(setPlayer(data))
                if (data.glbUrl === null || data.displayName == null) {
                    setHasCookie(false)
                }
                if (data.glbUrl) {
                    let modelId = data.glbUrl?.split('/').pop()?.split('.')[0] ?? null;
                    window.avatarId = modelId
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    }, []);

    async function fetchData(url: string): Promise<any> {
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    }
    
    if (id === hostId) {
         console.log("setting Global Host")
        globalIsHost.isHost = true
    }

    // probably wont need this as is Cookie Based
    // useEffect(() => {
    //     let avatarUrl = Cookies.get("avatarUrl");
    //     let username = Cookies.get("username");

    //     if (avatarUrl === undefined || username === undefined) {
    //         setHasCookie(false);
    //     }
    // }, []);

    if (id === bypassId) {
        return <UnityClient id={id} />;
    }

    return(
        <div className="App">
            
            {
                hasCookie ? <UnityClient id={id}/> : <AvatarPage redirect={setHasCookie}/>
            }
        </div>
    )
}